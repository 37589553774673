#Inspection_view_info {
  overflow: unset;
}

.card_view_info {
  background: rgb(103, 93, 122) !important;
  border-radius: 20px !important;
  height: 100%;
  padding: 0 !important;
}

.badge_view_info {
  text-align: left !important;
  margin: 7px 0px;
  color: rgb(154, 154, 177);
  font-size: 14px;
  word-break: break-word;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 5px;
  height: max-content;
}

.badge_view_info_title {
  background-color: #321640 !important;
  width: fit-content !important;
  padding: 0.5 1.5 !important;
  font-size: 20px !important;
}

.card_view_info .card_icon {
  position: absolute;
  right: 10%;
  top: -8%;
  border-radius: 50%;
  color: black;
  background: rgba(50, 22, 64, 0.5);
  color: white;
}

.status_circle {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  color: #fff;
  font-weight: bolder;
  font-size: larger;
}

@media only screen and (max-width: 1380px) {
  .badge_view_info {
    font-size: 80%;
    padding: 5px;
  }
  .badge_view_vehicle {
    font-size: 80% !important;
  }
  .badge_view_info_title {
    font-size: 80% !important;
  }
  .status_circle {
    height: 120px;
    width: 120px;
    font-size: large;
  }
  .car_status_svg > .car_svg {
    height: 200px !important;
  }
}
