#Workspace {
  position: relative;
  padding-top: 68px;
  padding-bottom: 58px;
  .damage-point {
    text-align: center;
    line-height: 1;
    z-index: 101;
    &.full {
      transition: 0.5s;
    }

    .agent-point {
      position: absolute;
      right: 100%;
      top: 0;
      line-height: 20px;
      background: #e9eaed;
      color: #000;
      border-radius: 10%;
      width: 35px;
      height: 18px;
      font-size: 13px;
    }
    .admin-point {
      position: absolute;
      right: 100%;
      top: 0;
      line-height: 18px;
      background: #e9eaed;
      color: #000;
      border-radius: 15px;
      width: 25px;
      height: 18px;
    }
    .admin-point-right {
      position: absolute;
      left: 100%;
      top: 0;
      line-height: 18px;
      background: #e9eaed;
      color: #000;
      border-radius: 15px;
      width: 25px;
      height: 18px;
    }
    & > i {
      color: #ffed89;
      line-height: 1;
      font-size: 21px;
    }
    & > span {
      font-family: $font-family-heading;
      font-size: 0.75rem;
      color: #ffed89;
      text-transform: uppercase;
      letter-spacing: 1px;
      vertical-align: middle;
      line-height: 1;
      margin-left: 0;
    }
    .agent-damage {
      position: absolute;
      left: 100%;
      top: 0;
      line-height: 21px;
    }
  }
  .custom-file {
    display: inline;
    position: relative;
    input {
      visibility: hidden;
      position: absolute;
    }
  }
  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  .buttons {
    list-style-type: none;
    margin: 0;
    padding: 0;
    float: right;
    & > li {
      display: inline;
      line-height: 1.5;
      font-size: 0.85rem;
      & + li {
        margin-left: 1.5rem;
      }
    }
    .btn {
      font-size: 0.85rem;
      padding: 0.3rem 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: 0;
    }
  }
  .btn-show-cage {
    position: absolute;
    top: -15px;
    right: 15px;
  }
  .rnd-cage {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .box-hotkey {
    background: #fff;
    opacity: 0.8;

    .title {
      color: #321640;
      text-transform: uppercase;
      font-size: 32px;
    }

    .btn-hotkeys {
      display: flex;

      .hotkey {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          background-color: #321640;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 18px;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }
  #Logs {
    background: #161313;
    color: #ffffff;
    min-height: 100vh;

    a {
      color: #ffffff;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #495057;
    }

    .ct-width {
      max-width: 200px;
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;

      h5 {
        margin-bottom: 2px;
      }
    }

    .card-cio {
      padding: 25px 15px;
      background: #282828;
    }

    .form-filter {
      input,
      select {
        max-width: 300px;
      }
      select:focus {
        box-shadow: none;
        border: none;
      }
    }

    .header-info {
      position: relative;

      .unset-position {
        position: unset;
      }
    }

    .az-dashboard-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdd4e0;
      margin-bottom: 20px;

      .nav {
        margin-left: auto;
      }
    }

    .all-logs {
      .filter {
        padding: 25px 15px;
        background: #282828;
        margin: 0 0 30px 0;
        font-family: 'Geogrotesque';

        .col-zs {
          width: 300px;
        }

        .col-2zs {
          input {
            min-width: 240px;
          }
        }

        input,
        select {
          border-radius: 0;
        }
      }
    }

    .logs-list {
      font-size: 0.85rem;
      color: #ffffff;
      overflow: hidden;

      .view-detail {
        margin-top: -30px;

        .bordered {
          margin-left: 30px;
          margin-top: 30px;
          //border-bottom: 1px solid #cccccc36;
        }

        dt {
          color: #ffffff;
        }

        dd {
          margin-left: 25px;
        }
      }
      .gt-font {
        font-family: 'Geogrotesque';
      }

      .flex-c {
        display: flex;
        border-bottom: 1px solid #cccccc36;
        margin-left: -5px;
        margin-right: -5px;
        padding: 10px 0 30px;

        > div {
          padding: 5px;
        }

        .col-inspection {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8%;
          flex: 0 0 8%;
          max-width: 8%;
        }

        .col-status {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 5%;
          flex: 0 0 5%;
          max-width: 5%;
        }

        .col-response {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 29%;
          flex: 0 0 29%;
          max-width: 29%;
        }

        .col-data {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 30%;
          flex: 0 0 30%;
          max-width: 30%;
        }

        .col-time {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 8%;
          flex: 0 0 8%;
          max-width: 8%;
        }

        .col-url {
          -webkit-box-flex: 0;
        }

        pre {
          color: #ffffff;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          margin-bottom: 0;
          line-height: 14px;
        }
      }
    }

    .live {
      p {
        margin-bottom: 0;

        span {
          margin-right: 15px;
        }
      }
    }

    .s-mode {
      font-size: 12px;
      ::-webkit-scrollbar {
        width: 4px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .flex-c {
        padding: 10px 0;

        .col-time {
          -ms-flex: 0 0 20%;
          flex: 0 0 20%;
          max-width: 20%;
        }
        .col-status {
          -ms-flex: 0 0 5%;
          flex: 0 0 5%;
          max-width: 5%;
        }
        .col-url {
          -ms-flex: 0 0 40%;
          flex: 0 0 40%;
          max-width: 40%;
        }
      }

      .view-detail {
        margin-top: 0;
      }
    }

    .card {
      width: 100%;
      min-width: 100%;
      background-color: transparent;

      .nav-item {
        text-transform: uppercase;
      }

      .icon-lg {
        font-size: 50px;
      }

      .border-md-right {
        border-right: 1px solid #585555;
      }
    }

    .is-fail {
      color: #ff0018 !important;

      pre {
        color: #ff0018 !important;
      }
    }
    .is-succ {
      color: #00ff3a !important;

      pre {
        color: #00ff3a !important;
      }
    }
  }
  & > .routes {
    position: relative;
  }
  .InspectionBoard {
    .Waitting {
      padding: 1rem;
      &.opacity {
        .group-wait {
          .img-wait {
            opacity: 1;
          }
          .group-text {
            opacity: 1;
          }
        }
      }
      .group-wait {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 520px;
        background: #321640;
        border-radius: 10px;
        position: relative;
        .group-text {
          opacity: 0.3;
          transition: 1s;
          position: relative;
          top: -55px;
          left: 10px;
          .loading {
            margin-bottom: 55px;
          }
          h1 {
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 45px;
          }
          button {
            background: #fff;
            color: #321640;
            padding: 10px 25px;
            border-radius: 25px;
          }
          #Spinner_icon {
            & > div {
              position: absolute;
              left: 42%;
              width: 75px;
              height: 75px;
              > div {
                border-color: #fff;
                border-left-color: rgba(50, 22, 64, 0.4);
              }
              & > i {
                font-size: 30px;
                width: 30px;
                height: 30px;
                color: #fff;
              }
            }
          }
        }
        .img-wait {
          position: absolute;
          width: 55%;
          top: 60%;
          opacity: 0.3;
          transition: 1s;
        }
        .layout-top {
          position: absolute;
          width: 0;
          height: 0;
          top: 0;
          border-left: 400px solid transparent;
          border-right: 400px solid transparent;
          border-top: 65px solid #e9eaed;
        }
      }
    }

    .Actions {
      padding: 1rem;
      .subtitle {
        text-align: center;
      }
    }
    .VehiclePhoto {
      position: relative;
      height: 100%;
      img {
        border-radius: 5px;
        width: 100%;
      }
      .rnd-damage-box {
        .rnd-damage {
          border: 2px dashed rgb(255, 237, 137);
          width: 100%;
          height: 100%;
          &:hover {
            opacity: 1;
            border: 2px dashed rgb(255, 237, 137);
          }
        }
      }

      .rnd-cage {
        border: 2px solid rgba(255, 255, 255, 0);
        z-index: 100;
        border: 4px dashed red;
        &:hover {
          border: 4px dashed red;
        }
      }
      .imageHolder {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
      }
      .list-damage {
        position: absolute;
        left: 0;
        bottom: 0;
        line-height: 1;
        border-radius: 0;
        max-height: 200px;
        overflow-y: auto;
        & > li {
          padding: 0.5rem;
          text-align: left;
          background-color: #fff;
          font-size: 0.8rem;
          opacity: 0.75;
          &:hover {
            opacity: 0.9;
          }
        }
      }
      #Results {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
      .img-thumb {
        position: relative;
        z-index: 2;
        display: flex;
        height: 100%;
        .tool-image {
          opacity: 0;
        }
        img {
          opacity: 0;
          margin-right: 10px;
        }
        .group-thumb {
          position: absolute;
          left: 45%;
          top: 25%;
          z-index: 999;
          span {
            font-family: $font-family-heading;
            font-size: 18px;
            text-transform: uppercase;
          }
          #Spinner_icon {
            & > div {
              position: absolute;
              left: 40%;
              top: 0;
              width: 90px;
              height: 90px;
              transform: translateX(-25px);
              & > i {
                font-size: 34px;
                width: 34px;
                height: 34px;
              }
            }
          }
        }
      }
      .img-full {
        transition: 0.5s;
        position: relative;
        z-index: 3;
        width: 100%;
        overflow: hidden;
        img {
          cursor: zoom-in !important;
        }
        .layout-front {
          position: absolute;
          top: 0;
          left: 0;
          width: 30%;
          height: 100%;
          background: black;
          opacity: 0.3;
          z-index: 1;
        }
        .layout-rear {
          position: absolute;
          top: 0;
          right: 0;
          width: 30%;
          height: 100%;
          background: black;
          opacity: 0.3;
          z-index: 1;
        }
      }
      .tool-image {
        font-weight: bold;
        .react-select-damage-right {
          left: 100%;
          top: -5px;
        }
        .react-select-damage-left {
          right: 100%;
          top: -5px;
        }
        .react-select-damage-right,
        .react-select-damage-left {
          font-weight: bold;
          position: absolute;
          width: 325px;
          &.top {
            .react-select__menu {
              position: absolute !important;
              top: -165px;
              left: 0;
              font-weight: bold;
              .react-select__menu-list {
                height: 150px;
                font-weight: bold;
                .react-select__option {
                  font-size: 13px;
                }
              }
            }
          }
          &.bot {
            .react-select__menu {
              position: absolute !important;
              top: 15px;
              left: 0;
              .react-select__menu-list {
                .react-select__option {
                  font-size: 13px;
                }
              }
            }
          }
          .react-select__control {
            min-height: 32px;
            height: 32px;
            .react-select__value-container {
              padding: 0px 8px;
              height: 32px;
              top: -3px;
              .react-select__single-value {
                font-size: 13px;
                top: 65%;
                .react-select__input {
                  height: 21px;
                }
              }
            }
            .react-select__indicators {
              padding: 0;
              position: relative;
              top: -2px;
              .react-select__indicator {
                padding: 0;
              }
            }
          }
        }
        .count-down {
          position: absolute;
          top: 15px;
          right: 25px;
          z-index: 9999;
          &.red {
            .timer {
              color: #c82233;
            }
            .lds-ring div {
              border: 6px solid #c82233;
              border-color: #c82233 transparent transparent transparent;
            }
          }
          .timer {
            position: absolute;
            width: 100%;
            height: 60px;
            left: 1px;
            align-items: center;
            justify-content: center;
            display: flex;
            color: #321640;
            font-size: 20px;
            z-index: 999;
          }
          .lds-ring {
            display: inline-block;
            position: relative;
            width: 60px;
            height: 60px;
          }
          .lds-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 52px;
            height: 52px;
            margin: 4px;
            border: 6px solid #321640;
            border-radius: 50%;
            background-color: #fff;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #321640 transparent transparent transparent;
          }
          .lds-ring div:nth-child(1) {
            animation-delay: -0.45s;
          }
          .lds-ring div:nth-child(2) {
            animation-delay: -0.3s;
          }
          .lds-ring div:nth-child(3) {
            animation-delay: -0.15s;
          }
          @keyframes lds-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .custom-switch .custom-control-label::after {
          transform: translateX(0);
        }
        .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          transform: translateX(0.75rem);
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          opacity: 1;
        }
        .custom-switch .custom-control-label::before {
          opacity: 0.7;
        }
      }
    }
    .inspect-photo {
      position: relative;

      .group-btn {
        display: flex;
        margin-bottom: 10px;
        button {
          width: 50%;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
      .damage-view {
        .component {
          width: 100%;
          display: block;
        }
        .svg-cage {
          overflow: hidden;
          #svg-cage {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
            .show-warning {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 9999;
              span {
                padding: 3px 5px;
                border-radius: 5px;
                background: #321640;
                color: #fff;
                text-transform: uppercase;
                font-size: 16px;
                text-align: center;
              }
            }
          }
          svg {
            width: 100%;
            height: 100%;
            transition: 1s;
            g {
              path {
                transition: 0.5s;
                opacity: 0.5;
              }
            }
            .svg-hover {
              transition: 0.5s;
              opacity: 1;
            }
          }
        }
        .react-select-damage {
          width: 150px;
          position: relative;
          display: inline-block;
          .react-select__control {
            font-weight: bold;
            .react-select__indicators {
              padding: 0;
              position: relative;
              top: -2px;
              .react-select__indicator {
                padding: 0;
              }
            }
          }
          .react-select__menu {
            position: absolute !important;
            top: 35px;
            left: 0;
            width: 200px;
            z-index: 999;
            font-weight: bold;
            .react-select__menu-list {
              font-weight: bold;
              .react-select__option {
                font-size: 13px;
                font-weight: bold;
              }
            }
          }
        }
        li {
          border-radius: 15px;
          margin-bottom: 5px;
          background: rgba(255, 255, 255, 0.6);
          position: relative;
          padding: 8px 10px;
          .reject-damage {
            position: absolute;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background: #321740;
            top: 0;
            left: 0;
            border-radius: 30px;
            color: #fff;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;

            padding: 10px 25px;
            transition: 1s;
            opacity: 0;
            &.show {
              opacity: 1;
              z-index: 999;
            }
          }
          &:hover {
            opacity: 1;
          }
        }
        .group-button {
          display: flex;
          button {
            width: 50%;
            position: relative;
            margin-left: 5px;
            opacity: 0.5;
            &:first-child {
              margin-right: 5px;
            }
            &.act {
              opacity: 1 !important;
              &::after {
                content: '\2713';
                position: absolute;
                right: 10px;
                font-size: 22px;
                top: 0px;
              }
            }
          }
        }
      }
      .information {
        border-radius: 5px;
        opacity: 0.9;
        .react-select__control,
        .select {
          border-radius: 25px;
        }
        .react-select__single-value {
          &::before {
            border: 1px solid #000;
          }
        }
      }
      .checkAcp {
        display: flex;
        flex-direction: column;

        .false {
          .group-false {
            text-align: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            span {
              display: block;
              text-transform: uppercase;
              font-size: 24px;
              margin-bottom: 15px;
            }
            img {
              width: 60%;
            }
          }
        }
        .modal-full {
          min-width: 95%;
          .group-img {
            display: flex;
            .item-img {
              overflow: hidden;
              width: 50%;
              margin: 10px;
              margin-bottom: 0;
              &:nth-child(2n + 1) {
                margin-left: 0;
              }
              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }
        }

        .waitting {
          .modal-content {
            .modal-body {
              text-align: center;
              padding: 85px 0;
              background: #e9e9ed;
              span {
                font-size: 27px;
                text-transform: uppercase;
              }
            }
          }
        }
        .modal-content {
          button {
            width: auto;
            margin-bottom: 0;
          }
        }
        button {
          width: 100%;
          margin-bottom: 10px;
          i {
            margin-right: 5px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @keyframes opaa {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
}
.group-color {
  .list-color {
    .item-color {
      display: inline-block;
      .child-item {
        span {
          color: #000;
        }
        label {
          height: 45px;
          border-radius: 15px;
          text-transform: uppercase;
          position: relative;
          border: 2px solid #000;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active-color {
            &:after {
              content: '\2713';
              font-size: 17px;
              color: #fff;
              position: absolute;
              display: flex;
              justify-content: center;
              width: 50%;
              height: 50%;
              border-radius: 15px;
              background: #000;
            }
          }

          &:hover {
            span {
              visibility: visible;
              opacity: 1;
            }
          }
          span {
            visibility: hidden;
            width: 150px;
            color: #000;
            background-color: #fff;
            text-align: center;
            border-radius: 6px;
            position: absolute;
            z-index: 999;
            top: 125%;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            -webkit-transition: opacity 1s;
            transition: opacity 1s;
            &::after {
              content: '';
              position: absolute;
              bottom: 100%;
              left: 45%;
              margin-left: -5px;
              border: 5px solid transparent;
              border-bottom-color: #fff;
            }
          }
        }
        input {
          text-align: center;
          border-radius: 25px;
        }
      }
    }
  }
}
.display-duration {
  & > div {
    display: inline-block;
    text-align: center;
    padding: 1rem;
    & > span {
      display: block;
      font-size: 3rem;
      text-transform: uppercase;
      color: #321342ff;
      line-height: 1;
      & + span {
        font-size: 0.8rem;
        color: #32134266;
      }
    }
  }
}

button.btn-ai {
  outline: none;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 0;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: calc(1.5em + 0.75rem + 1px);
  border-radius: 6px;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;
  span {
    padding: 0 10px;
  }
}
.wrapp-vin-ai {
  position: relative;
  width: 100%;
  height: 40px;
  .run-vin {
    color: #fff;
    pointer-events: none;
    background: transparent;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    padding: 10px 0 0 0;
    animation: floatText 1s ease;
    .label-vin {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-right: 10px;
    }
    .content-vin {
      letter-spacing: 1px;
    }
  }
}
@keyframes floatText {
  from {
    top: -50px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
