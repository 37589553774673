$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
	Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-heading: "Geogrotesque";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&display=swap');
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.css";
@import "./_sass/general";
@import "./_sass/customize";
@import "./_sass/objects";
@import "./_sass/work";
@import "./_sass/SpinnerIcon";
@import "./_sass/cageProcess";
@import "./_sass/animation";
@import "./_sass/label";
@import "./_sass/instruction";
@import "./_sass/training";
@import "./_sass/InspectionViewInfo";