#label {
	.nav-pills {
		background: #321640;
		border-radius: 10px;
		.nav-item {
			a {
				color: #fff;
				padding: 20px 20px;
				border-radius: 0;
				span {
					&::before {
						content: "-";
						margin-right: 5px;
					}
					transition: 1s;
					display: block;
					font-size: 18px;
				}
				&:hover {
					border-radius: 0;
					background: #e9e9ed;
					color: #321640;
					span {
						transform: translateX(15px);
					}
				}
				&.active {
					border-radius: 0;
					background: #e9e9ed;
					color: #321640;
					span {
						transform: translateX(15px);
					}
				}
			}
			@media screen and (max-width: 1366px) {
				a {
					padding: 15px 10px;
					span {
						font-size: 15px;
					}
				}
			}
		}
	}
	.tab-content {
		.car {
			.group {
				position: fixed;
				right: 0;
				top: 50%;
				display: flex;
				flex-direction: column;
				width: 225px;
				.btn-car {
					background: #321640;
					padding: 30px 15px;
					margin: 1px 0;
					color: #fff;
					text-align: left;
					border-radius: 20px;
					transition: 1.5s;
					transform: translateX(100px);
					&.active {
						transform: translateX(20px);
						span {
							transform: translateX(50px);
						}
					}
					span {
						position: absolute;
						left: 20px;
						width: 100%;
						height: 100%;
						top: 18px;
						font-size: 18px;
						transform: translateX(0px);
						transition: 1.5s;
					}
					&:hover {
						transform: translateX(20px);
						span {
							transform: translateX(50px);
						}
					}
				}
				@media screen and (max-width: 1366px) {
					top: 55%;
					.btn-car {
						padding: 24px 10px;
						transform: translateX(130px);
						span {
							top: 14px;
							font-size: 15px;
						}
						&.active {
							transform: translateX(80px);
							span {
								transform: translateX(10px);
							}
						}
						&:hover {
							transform: translateX(80px);
							span {
								transform: translateX(10px);
							}
						}
					}
				}
			}
			.image-svg {
				&.rear,
				&.front {
					width: 66%;
					position: relative;
					left: 4%;
					@media screen and (max-width: 1366px) {
					}
				}
				&.left {
					width: 88%;
					position: relative;
					top: 90px;
					@media screen and (max-width: 1366px) {
					}
				}
			}
			.image-png {
				&.rear,
				&.front {
					position: absolute;
					width: 400px;
					height: 300px;
					top: 0;
					right: 20px;
					border-radius: 10px;
					@media screen and (max-width: 1366px) {
						width: 230px;
						height: 150px;
					}
				}
				&.left {
					position: absolute;
					width: 450px;
					height: 220px;
					top: 0;
					right: 0;
					border-radius: 10px;
					@media screen and (max-width: 1366px) {
						width: 320px;
						height: 160px;
					}
				}
			}
		}
	}
}
