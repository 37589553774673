.instruction-inspect {
	.Start {
		background-color: #e9eaed !important;
		padding-bottom: 0 !important;
		top: 0 !important;
	}
}
#intruction {
	position: relative;
	.box {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		margin: 50px 0;
		padding: 125px 0;
		overflow: hidden;
		border-radius: 15px;
		&:hover {
			cursor: pointer;
			.box-move {
				transform: translateY(0);
			}
			.group {
				background: #fff;
				color: #321640;
				animation: box-up-down 5s infinite;
			}
		}
		.box-move {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #321640;
			transition: 1.5s;
			transform: translateY(-500px);
		}
		.group {
			position: relative;
			z-index: 2;
			text-transform: uppercase;
			background: #321640;
			color: #fff;
			border-radius: 50%;
			width: 180px;
			height: 180px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			transition: 2s;
			i {
				display: block;
				font-size: 55px;
			}
			span {
				display: block;
				margin: 0;
				font-size: 25px;
			}
		}
	}
}
@keyframes box-up-down {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-50px);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(-50px);
	}
	100% {
		transform: translateY(0);
	}
}
