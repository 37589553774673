#Training_list {
	.listTask {
		.item {
			transition: 1s;
			&:hover {
				transition: 1s;
				transform: scale(1.1);
			}
		}
	}
}
