.navbar-nav.disable {
  pointer-events: none;
}
img {
  border-radius: 10px;
}
.display {
  font-weight: 300;
  line-height: 1.2;
  &-1 {
    font-size: 4rem !important;
  }
  &-2 {
    font-size: 2.75rem !important;
  }
  &-3 {
    font-size: 2rem !important;
  }
  &-4 {
    font-size: 1.5rem !important;
  }
  &-5 {
    font-size: 1.2rem !important;
  }
  &-6 {
    font-size: 1rem !important;
  }
  &-7 {
    font-size: 0.9rem !important;
  }
  &-8 {
    font-size: 0.8rem !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
}
#button-tooltip {
  .tooltip-inner {
    background: #fff;
    color: #000;
    font-weight: bold;
  }
}
button.nav-link {
  border: none;
}

.small {
  font-size: 90%;
}

.btn {
  font-family: $font-family-heading;
  text-transform: uppercase;
  &-normal {
    font-family: $font-family-sans-serif;
  }
  &-primary {
    background: $cl-brand-2;
    color: $cl-brand-8;
    &:hover {
      background: lighten($cl-brand-2, 5%);
      color: $cl-brand-8;
    }
  }
  &-tiny {
    padding: 0 0.25rem;
    line-height: 1.45;
  }
  &-note {
    display: none;
    position: absolute;
    right: 5px;
    line-height: 1.25;
  }
  &-list {
    .btn + .btn {
      margin-left: 0.5rem;
    }
  }
  &-modal {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  &-lb-dark {
    border-left: 5px solid $dark;
  }
  &-head {
    outline: none !important;
    box-shadow: none !important;
  }
}
*:hover > .btn-note {
  display: inline-block;
}

.table {
  th {
    font-family: $font-family-heading;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  th,
  td {
    position: relative;
    font-size: 0.85rem;
  }
  &.table-middle {
    th,
    td {
      vertical-align: middle;
    }
  }
  .btn-group {
    .btn + .btn {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.btn-group {
  .btn {
    outline: none;
    box-shadow: none;
  }
}

.card {
  &-title {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &-subtitle {
    font-size: 0.85rem;
  }
  &-header {
    position: relative;
    font-weight: 700;
    color: $cl-brand-2;
    text-transform: uppercase;
    font-family: $font-family-heading;
    .btn.btn-link {
      font-weight: 700;
      color: $cl-brand-2;
      &:hover,
      &:focus {
        color: $cl-brand-2;
        text-decoration: none;
      }
    }
    .actions {
      position: absolute;
      top: 10px;
      right: 15px;
      .btn-pos {
        border-radius: 0;
        padding: 0.25rem 0.5rem;
      }
    }
    .menu {
      cursor: pointer;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
      & + .menu {
        margin-left: 1.5rem;
      }
    }
  }
}

.unavailable {
  text-align: center;
  padding: 40px 0;
}

.accordion {
  .card .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    &.collapsed {
      color: $cl-brand-3;
    }
    .btn-link {
      color: $cl-brand-2;
      padding-left: 0;
      &.collapsed {
        color: $cl-brand-4;
      }
    }
  }
}

.card-columns .card,
.card-deck .card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.text {
  &-head {
    font-family: $font-family-heading;
  }
  &-normal {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: initial;
  }
  &-logan {
    color: $cl-brand-3;
  }
  &-secondary-border {
    display: inline-block;
    color: $secondary;
    &:after {
      content: '';
      display: block;
      margin-top: 0.5rem;
      width: 70%;
      border: 2px solid $secondary;
      border-radius: 6px;
      -webkit-transition: width 200ms; /* Safari */
      transition: width 200ms;
    }
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }
  &-sm {
    font-size: 0.9rem;
  }
  &-xsm {
    font-size: 0.8rem;
  }
  &-xxsm {
    font-size: 0.65rem;
  }
  &-bold {
    font-weight: 700 !important;
  }
  &-strike {
    text-decoration: line-through;
  }
}

.badge {
  &-group {
    .badge {
      margin: 0 5px 3px 0;
    }
  }
  &-group-lg {
    .badge {
      font-size: 0.85rem;
      padding: 6px 10px;
    }
  }
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  &-muted {
    background: #e3e3e3;
    color: $cl-brand-3;
  }
  &-blackcurrant {
    background: $cl-brand-1;
    color: $cl-brand-8;
  }
  &-bullet {
    position: relative;
    padding-left: 1.25rem;
    &::before {
      content: '•';
      font-size: 1.5rem;
      position: absolute;
      left: 5px;
      top: 9px;
      line-height: 0;
    }
  }
}

.card-columns {
  column-count: 5;
}

.pagination {
  .page-link {
    span,
    i {
      pointer-events: none;
    }
  }
  .input-group {
    &-text,
    .form-control {
      border-radius: 0;
    }
    .form-control {
      width: 70px;
      text-align: right;
      padding-top: 5px;
    }
  }
}

.custom-checkbox {
  .custom-control-label.danger {
    font-weight: 700;
    font-family: $font-family-heading;
  }
  .custom-control-input {
    &:disabled:checked ~ .custom-control-label.danger {
      color: $danger;
      &::before {
        background-color: $danger;
        border-color: darken($danger, 10%);
      }
    }
  }
}

.alert {
  &-logan {
    background: $cl-brand-7;
    color: $cl-brand-3;
  }
}

.progress-timeline {
  .progress {
    position: relative;
    margin: 3px 0;
    &-bar {
      background-color: transparent;
      &.bg-warning {
        color: #333;
      }
      &.bg-blackcurrant {
        background: $cl-brand-1;
      }
      &.admin-inspected {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $danger;
        color: $white;
        opacity: 0.4;
        text-align: left;
        padding-left: 3px;
      }
    }
  }
  & > div + div {
    margin-top: 5px;
  }
}

.modal {
  &-full {
    min-width: 100%;
  }
  &-size {
    position: absolute;
    top: 5px;
    left: 5px;
    .btn {
      min-width: 25px;
      border-radius: 0;
      outline: 0;
      border: 0;
      box-shadow: none;
      font-size: 0.9rem;
      opacity: 0.75;
      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }
}

.customize-edit-icon {
  padding: 3px;
  border-radius: 4px;
  background: $cl-brand-1;
  margin-right: 5px;
  color: #ffffff;
  cursor: pointer;
}
.custom-inspect-icon {
  padding: 0.25rem;
  border-radius: 4px;
  background: #f05d61;
  margin-left: 5px;
  color: #ffffff;
  cursor: pointer;
  height: 20px;
  &.bg-light {
    background: #f1f9f8;
    color: #1d2430;
    box-shadow: inset 0 0 2px 0px rgba(0, 0, 0, 0.3);
    &:hover {
      color: #f1f9f8;
      background: #1d2430 !important;
    }
  }
}
.modal {
  &-header {
    padding: 0.65rem 1rem;
  }
  &-title {
    width: 100%;
    text-transform: uppercase;
    font-size: 1.15rem;
    color: #1d2430;
  }
  .close {
    outline: none;
  }
}

.table_wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.container-modal {
  font-size: 14px;
  input {
    font-size: 14px;
  }

  .modal-header {
    background-color: #343a40;
    color: #fff;
    text-transform: uppercase;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
}
.group-title {
  .title-name {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    .inspect-name {
      padding: 5px 10px 5px 10px;
      background: #321640;
      color: #fff;
      border-radius: 5px;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}
.infobar {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  .info-car {
    h4 {
      font-size: 24px;
      margin-bottom: 5px;
      opacity: 0.8;
      span {
        text-transform: uppercase;
        margin-right: 5px;
      }
      i {
        position: relative;
        top: 3px;

        font-size: 32px;
        margin-right: 5px;
      }
    }
  }
  .image-svg {
    margin: 5px 0 10px 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    animation: opaa 5s infinite;
    .group-image {
      position: relative;
      width: 39%;
      img {
        width: 100%;
      }
    }
  }
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    flex-direction: column;
    & > li {
      display: inline-block;
      font-family: $font-family-heading;
      margin-bottom: 3px;
      span {
        font-size: 15px;
        margin-right: 5px;
        line-height: 27px;
        text-transform: uppercase;
        &.badge {
          padding: 0 10px !important;
        }
      }
    }
  }
}
.modal-full {
  min-width: 95%;
  .group-img {
    display: flex;
    .item-img {
      overflow: hidden;
      width: 50%;
      margin: 10px;
      margin-bottom: 0;
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
.ping-priority {
  animation: ping 2s infinite;
}
@keyframes ping {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}
.react-datepicker {
  display: flex;
}
