$sidebar-space: 8rem;
$sidebar-space-min: 2.5rem;
$sidebar-logo-space: 3.5rem;
#Noti {
	position: fixed;
	width: 65px;
	height: 65px;
	bottom: 20px;
	right: 20px;
	border-radius: 50%;
	background: #321640;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;
	transition: 1s;
	&.lack {
		animation: lack 1s;
	}
	i {
		cursor: pointer;
		font-size: 30px;
		color: #fff;
		&.rotate {
			animation: rotateIcon 2s infinite;
		}
	}

	.noti-list {
		position: absolute;
		right: 25px;
		top: -180px;
		width: 220px;
		height: 170px;
		border-radius: 10px;
		border: 1px solid #000;
		background: #e9eaed;
		border-bottom-right-radius: 0;
		padding: 10px 15px;
		display: flex;
		justify-content: center;
		.list {
			height: 140px;
			overflow: scroll;
			.item {
				margin-bottom: 5px;
			}
			a {
				font-size: 15px;
				color: #fff;
			}
		}
		.list::-webkit-scrollbar {
			display: none;
		}
		.triangle {
			position: absolute;
			right: -1px;
			bottom: -19px;
			width: 0;
			height: 0;
			border-top: 20px solid #321640;
			border-left: 20px solid transparent;
		}
	}
	.noti-bell {
		position: absolute;
		top: -7px;
		left: -7px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ff0018;
		animation: rotateBell 1s infinite;
		transition: 0.5s;
		i {
			font-size: 21px;
		}
	}
	.noti-box {
		position: absolute;
		left: -175px;
		top: 17px;
		width: 160px;
		height: 35px;
		display: none;
		align-items: center;
		justify-content: center;
		background: #321640;
		color: #fff;
		border-radius: 10px;
		.triangle {
			position: absolute;
			right: -12px;
			width: 0;
			height: 0;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
			border-left: 15px solid #321640;
		}
		&.show-noti {
			display: flex !important;
		}
	}
	@keyframes lack {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(-25px);
		}
		100% {
			transform: translateY(0);
		}
	}
	@keyframes rotateBell {
		0% {
			transform: rotate(40deg);
		}
		50% {
			transform: rotate(-40deg);
		}
		75% {
			transform: rotate(40deg);
		}
		100% {
			transform: rotate(40deg);
		}
	}
	@keyframes rotateIcon {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
#Navbar {
	position: fixed;
	height: 68px;
	width: 100%;
	z-index: 999;
	.navbar {
		border-bottom: 1px solid #fff;
		padding: 0 1rem;
		background: #321640;
		.nav-link {
			font-family: $font-family-heading;
			text-transform: uppercase;
			text-align: center;
			padding: 0.5rem 1.25rem;
			i,
			span {
				opacity: 0.85;
			}
			span {
				display: block;
				font-size: 0.85rem;
				letter-spacing: 1px;
				margin: 0.5rem 0 0;
			}
			&:hover,
			&.active {
				background: rgba(255, 255, 255, 0.1);
				i,
				span {
					opacity: 1;
				}
			}
		}
	}
}
#Loading {
	position: fixed !important;
	z-index: 900;
	top: 0 !important;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.85) !important;
}

#Alert {
	position: fixed;
	z-index: 899;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	padding: 0.5rem 0;
	.alert {
		position: relative;
		display: inline-block;
		padding: 10px 45px 10px 15px;
		font-size: 0.9rem;
		background: #fff;
		.btn-close {
			position: absolute;
			top: 50%;
			right: 10px;
			padding: 0;
			margin-top: -13px;
		}
		&-errors {
			border: 2px solid #ed4f32;
		}
		&-done {
			border: 2px solid #15cd72;
		}
		&-notify {
			border: 2px solid #fad01a;
		}
	}
}

.custom-inspect-icon {
	padding: 3px;
	border-radius: 4px;
	background: red;
	margin-left: 5px;
	color: #ffffff;
	cursor: pointer;
}
.modal-inspect {
	min-width: 100%;
	margin: 0;
	height: 100%;
	.modal-content {
		height: 100%;
		overflow-y: auto;
	}
}
.show-modal {
	display: block;
}
#Cage_select {
	.react-select__menu {
		top: 10px;
		left: 0;
	}
}
#Pave_live {
	.agent-live,
	.inspections-live {
		margin-top: 22px;
	}

	.agent-live {
		.cursor-pointer {
			cursor: pointer;
		}
	}

	nav.nav-tabs {
		a {
			padding-left: 50px;
			padding-right: 50px;
			font-family: $font-family-heading;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $cl-brand-4;
			position: relative;

			.fa-times {
				position: absolute;
				right: 8px;
			}
		}
	}

	#Agents_table {
		.online {
			color: #15cd72;
		}
		td {
			span {
				border-radius: 2px;
				padding: 1px 5px 2px;
				color: #fff;
				background: $cl-brand-1;
				.fa {
					margin-left: 2px;
				}
			}
			span.inspect {
				background: green;
			}
			span.qc {
				background: #ffc107;
			}
			.icon {
				height: 16px;
				margin-left: 2px;
				padding-bottom: 2px;
			}
		}
	}
	.inspections-live {
		.list {
			width: 260px;
		}

		.content-btn-room.active {
			background: $green;
		}

		.content-btn-room {
			font-family: $font-family-heading;
			background: #321640;
			color: #fff;
			font-size: 14px;
			line-height: 26px;
			padding-left: 10px;
			padding-right: 10px;
			display: inline-block;
			width: 100%;
			cursor: pointer;
			border-radius: 4px;
			font-weight: bold;

			> label {
				margin-bottom: 0;
				cursor: pointer;
				float: left;
			}

			.info-content {
				float: right;

				label {
					cursor: pointer;
					margin-bottom: 0;
					margin-right: 6px;
				}

				.icon {
					width: 18px;
					padding-bottom: 4px;
				}
			}
		}

		.white {
			background: #ffffff;
		}

		.inspection-detail {
			.task-slots button.btn-primary {
				background: $cl-brand-1;
				border-color: $cl-brand-1;
			}

			.task-slots button.processing {
				background: $blue;
				color: #ffffff;
			}

			.task-slots button.qc {
				background: #ffc107;
				border-color: #ffc107;
				color: #ffffff;
			}

			.task-slots button {
				display: block;
				margin-bottom: 5px;
				padding: 2px 12px;

				label {
					margin-bottom: 0;
					margin-right: 10px;
					cursor: pointer;
				}

				label:last-child {
					margin-right: 5px;
				}

				.__mrl {
					margin-left: -5px;
				}

				.icon {
					width: 16px;
					padding-bottom: 2px;
				}
			}
		}
	}
}
