#CageProcess {
  #ImageProcessor {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 700px;
    max-height: 700px;
    position: relative;
    background: $cl-brand-8;
    border: 1px solid $cl-brand-6;
    @media screen and (max-width: 1520px) {
      min-height: 650px;
      max-height: 650px;
    }
    @media screen and (max-width: 1280px) {
      min-height: 580px;
      max-height: 580px;
    }
    .fullContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .svg-hoverable {
      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    #Results {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  .cage-label {
    .subtitle {
      @media screen and (max-width: 1280px) {
        font-size: 1rem;
      }
    }
    button {
      @media screen and (max-width: 1280px) {
        font-size: 0.9rem;
      }
    }
  }
  .label-data {
    position: relative;
    height: 100%;
    button {
      @media screen and (max-width: 1280px) {
        font-size: 0.9rem;
      }
    }
    .btn-delete-all {
      width: 100%;
      position: absolute;
      bottom: 50px;
      left: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 25px;
    }
    .btn-label {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 25px;
    }
  }

  #MappingDashboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: 85%;
    li {
      border-radius: 0;
      padding: 0.75rem 1rem;
      &:hover {
        cursor: pointer;
      }
    }
    path {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .card {
    li {
      label {
        font-size: 14px;
        margin: 0;
        font-family: $font-family-heading;
        &:hover {
          cursor: pointer;
        }
      }
      .delete-label {
        color: red;
        font-weight: bold;
        font-size: 18px;
        z-index: 999;
        position: absolute;
        top: 22px;
        right: 13%;
        @media screen and (max-width: 1520px) {
          right: 17%;
        }
      }
      select {
        cursor: pointer;
        @media screen and (max-width: 1280px) {
          font-size: 0.7rem;
        }
      }
    }
    .card-body {
      padding: 0;
    }
  }
}
